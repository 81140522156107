import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { reducer as notificationsReducer } from 'reapop';
import { History } from 'history';

const rootReducer = (history: History) =>
  combineReducers({
    notifications: (notificationsReducer() as any) as Reducer<Notification[]>,
    router: connectRouter(history),
    form
  });

export type StateType = Exclude<Parameters<ReturnType<typeof rootReducer>>[0], undefined>;

export default rootReducer;
