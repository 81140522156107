import React, { useState } from 'react';
import { Owner } from './graphql';
import { Section, TextField, FileField } from 'jbc-front/components/Form';
import { number as isNumber, exactLengthNumber } from 'jbc-front/utils/validators';
import { PlusSquare } from 'jbc-front/components/icons';

interface Props {
  owner: Owner;
}

const OwnerFields: React.SFC<Props> = ({ owner }) => {
  const [hasSecondIdImage, setHasSecondIdImage] = useState(false);
  const handler = () => setHasSecondIdImage(true);
  return (
    <div>
      <Section title={`${owner.name}さんの情報`}>
        <Section title="マイナンバー">
          <TextField name="myNumber" label="マイナンバー" validate={[isNumber, exactLengthNumber(12)]} required />
        </Section>
        {owner.ownerRequest.requestNumberConfirmation && (
          <Section title="番号確認書類">
            <FileField name="myNumberImage" label="番号確認書類" required />
            個人番号カード、通知カード、個人番号が記載された住民票の写し、住民票記載事項証明書など
          </Section>
        )}
        {owner.ownerRequest.requestIdentityConfirmation && (
          <Section title="身元確認書類">
            <FileField name="idImage0" label="身元確認書類" required />
            {!hasSecondIdImage && (
              <div className="u-ta-r u-mt20 u-mb20">
                <div role="button" tabIndex={0} onClick={handler} onKeyPress={handler} className="u-txt-addinfo">
                  <PlusSquare size={20} className="u-txt-addinfo-icon" />
                  身元確認書類を追加
                </div>
              </div>
            )}
            {hasSecondIdImage && (
              <div>
                <FileField name="idImage1" label="身元確認書類" />
              </div>
            )}
            各種運転免許証、パスポート、在留カード、外国人登録証明書など
          </Section>
        )}
        <Section title="マイナンバーの利用目的">
          <ul>
            {owner.ownerRequest.purposeOfUseMasters.map(purposeOfUse => (
              <li key={purposeOfUse.id}>{purposeOfUse.name}</li>
            ))}
          </ul>
        </Section>
      </Section>
    </div>
  );
};

export default OwnerFields;
