import { datadogRum } from '@datadog/browser-rum';

if (process.env.ENABLE_DATADOG) {
  datadogRum.init({
    applicationId: 'f0be0a1b-6a11-41a6-bd0b-87e1d972c2b0',
    clientToken: 'pub6c99a999fd145a883e3a5c21eac0817f',
    site: 'ap1.datadoghq.com',
    service: 'my_number',
    env: process.env.SERVER_ENV,
    version: process.env.COMMIT_REF,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [`${process.env.DATADOG_TRACING_ORIGIN}`]
  });
}
