import gql from 'graphql-tag';
export const REQUEST_QUERY = gql`
  query RequestQuery {
    request {
      id
      status
      owners {
        id
        __typename
        name
        ownerRequest {
          id
          requestIdentityConfirmation
          requestNumberConfirmation
          purposeOfUseMasters {
            id
            name
          }
        }
        ... on Employee {
          staffCode
        }
        ... on Dependent {
          lastName
          firstName
          birthday
        }
      }
    }
  }
`;

export const ANSWER_REQUEST = gql`
  mutation AnswerRequest($input: AnswerRequestInput!) {
    answerRequest(input: $input) {
      request {
        id
        status
      }
    }
  }
`;

export interface Owner {
  id: string;
  __typename: string;
  name: string;
  ownerRequest: OwnerRequest;
}

export interface Employee extends Owner {
  __typename: 'Employee';
  staffCode: null | string;
}

export interface Dependent extends Owner {
  __typename: 'Dependent';
  lastName: string;
  firstName: string;
  birthday: string;
}

export interface Request {
  id: string;
  status: 'working' | 'completed' | 're_requesting';
  owners: (Employee | Dependent)[];
}

export interface PurposeOfUseMaster {
  id: string;
  name: string;
}

export interface OwnerRequest {
  id: string;
  purposeOfUseMasters: PurposeOfUseMaster[];
  requestIdentityConfirmation: boolean;
  requestNumberConfirmation: boolean;
}

export interface Data {
  request?: Request;
}
