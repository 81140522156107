import '@babel/polyfill';
import 'whatwg-fetch';
import React from 'react';
import 'font-awesome/scss/font-awesome.scss';
import 'jbc-front/styles/default.scss';
import 'normalize.css';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import configureApollo from './configureApollo';
import ErrorBoundary from './components/ErrorBoundary';
import configureStore, { history } from './configureStore';
import Layout from './Layout';
import * as pages from './pages';
import './datadog';

const client = configureApollo('/graphql');
const store = configureStore(undefined);

const App: React.SFC<{}> = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          <Layout>
            <Switch>
              <Route path="/" exact component={pages.Input} />
              <Route path="/finished" exact component={pages.Finished} />
              <Route path="/start" exact component={pages.Start} />
            </Switch>
          </Layout>
        </ErrorBoundary>
      </ConnectedRouter>
    </Provider>
  </ApolloProvider>
);

export default App;
