import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import routerScroll from './routerScroll';
import rootReducers from './reducers';
import trimFields from './trimFields';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();
let middleware = [thunk, routerMiddleware(history), routerScroll, trimFields];

export default function configureStore(initialState: any) {
  const store = createStore(rootReducers(history), initialState, compose(applyMiddleware(...middleware)));
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
