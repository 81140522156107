import React from 'react';
import { Query, MResult, Mutation } from '../components/Graphql';
import * as input from '../input/graphql';
import { Form, makeInitialValues } from '../input/Form';
import { Redirect } from 'react-router';

export const Input: React.SFC<{}> = () => (
  <>
    <Query query={input.REQUEST_QUERY}>
      {({ data: { request } }: MResult<input.Data>) => {
        if (!request) {
          return (
            <div className="l-wrap-xs u-pt100">
              <div className="l-box-message">
                <p className="m-title-sub u-pt0 u-ta-c">入力依頼がキャンセルされました。</p>
              </div>
            </div>
          );
        }
        const { status, owners } = request;
        if (status === 'completed') {
          return <Redirect to="/finished" />;
        }
        return (
          <Mutation mutation={input.ANSWER_REQUEST}>
            {answerRequest => (
              <div>
                <div className="l-title-wrap">
                  <h1 className="m-title-main">マイナンバー入力</h1>
                </div>
                <Form owners={owners} initialValues={makeInitialValues(owners)} answerRequest={answerRequest} />
              </div>
            )}
          </Mutation>
        );
      }}
    </Query>
  </>
);
