import React from 'react';
import { RoumuLogo } from 'jbc-front/components/icons';
import styles from './Finished.scss';

export const Finished = () => (
  <div className={styles.wrap}>
    <div>
      <div className={styles.logo}>
        <RoumuLogo size={200} />
      </div>
      <p className={styles.thanks}>入力ありがとうございました</p>
      <p className={styles.text} />
    </div>
  </div>
);
