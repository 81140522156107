import React from 'react';
import { Redirect } from 'react-router';
import { parse } from 'query-string';

export const Start = ({ location: { search } }) => {
  const { token } = parse(search);
  console.log(token);
  if (token && typeof token === 'string') {
    sessionStorage.setItem('access_token', token);
    return <Redirect to="/" />;
  } else {
    return (
      <div className="l-wrap-xs u-pt100">
        <div className="l-box-message">
          <p className="m-title-sub u-pt0 u-ta-c">エラーが発生しました</p>
        </div>
      </div>
    );
  }
};
