import {
  Query as ApolloQuery,
  Mutation as ApolloMutation,
  QueryProps,
  QueryResult,
  MutationProps,
  MutationFn,
  MutationResult
} from 'react-apollo';
import { LoadingPage } from './LoadingPage';
import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import _ from 'lodash';
import fp from 'lodash/fp';
import { SubmissionError } from 'redux-form';
import { notify } from '../actions';
import Rollbar from 'rollbar';

const camel = (value: any) =>
  _.isArray(value)
    ? value.map(camel)
    : _.isObject(value)
    ? _.mapValues(_.mapKeys(value, (_v: any, k: string) => _.camelCase(k)), (v: any) => camel(v))
    : value;

export interface MResult<TData = any> extends Pick<QueryResult, Exclude<keyof QueryResult, 'loading' | 'data'>> {
  data: TData;
}

export interface MQueryProps<TData = any> extends Pick<QueryProps, Exclude<keyof QueryProps, 'children'>> {
  showLoading?: boolean;
  children: (result: MResult<TData>) => React.ReactNode;
}

export const Query: React.SFC<MQueryProps> = ({ children, showLoading = true, ...props }) => (
  <ApolloQuery {...props}>
    {({ loading, error, ...rest }: QueryResult): React.ReactNode => {
      if (loading) return showLoading ? <LoadingPage /> : null;
      if (error) {
        console.log(error);
        throw error;
      }
      return children(rest);
    }}
  </ApolloQuery>
);

export const getBaseError = (data: any): [any, any] => {
  if (_.isArray(data)) {
    let idx = 0;
    for (const v of data) {
      let [base, rest] = getBaseError(v);
      if (base) {
        return [base, fp.set(`[${idx}]`, rest, data)];
      }
      idx++;
    }
    return [null, null];
  }
  if (!_.isObject(data)) {
    return [null, null];
  }
  for (const [key, value] of Object.entries(data)) {
    if (key === 'base') {
      return [value, _.omit(data, 'base')];
    } else {
      let [base, rest] = getBaseError(value);
      if (base) {
        return [base, fp.set(key, rest, data)];
      }
    }
  }
  return [null, null];
};

const validateReadableFiles = async data => {
  if (_.isArray(data)) {
    const ary = await Promise.all(data.map(validateReadableFiles));
    if (ary.length === 0 || ary.every(v => v === undefined)) {
      return undefined;
    } else {
      return ary;
    }
  }
  if (!_.isObject(data)) {
    return undefined;
  }
  const ret = {};
  await Promise.all(
    Object.entries(data).map(([key, value]) => {
      if (value instanceof File) {
        return new Promise<void>(resolve => {
          const reader = new FileReader();
          reader.addEventListener('load', () => resolve());
          reader.addEventListener('abort', () => resolve());
          reader.addEventListener('error', () => {
            ret[key] = 'を正常にアップロードできませんでした。再度アップロードしてください。';
            resolve();
          });
          reader.readAsArrayBuffer(value);
        });
      } else {
        return validateReadableFiles(value).then(v => (v ? (ret[key] = v) : undefined));
      }
    })
  );
  return _.size(ret) ? ret : undefined;
};

const MutationRender: React.SFC<MutationProps & DispatchProp> = ({ children, dispatch, ...props }) => (
  <ApolloMutation {...props}>
    {(mutation: MutationFn, result: MutationResult) =>
      children(async (...args) => {
        try {
          const res = await mutation(...args);
          return res;
        } catch (err) {
          const msg = _.get(err, 'graphQLErrors[0].problems[0].explanation') || _.get(err, 'graphQLErrors[0].message');
          if (msg) {
            if (_.get(err, 'graphQLErrors[0].extensions.code') === 'BROKEN_MULTIPART_REQUEST') {
              const input = args[0] && args[0].variables && args[0].variables.input;
              const errors = await validateReadableFiles(input || {});
              notify(msg, 'error')(dispatch);
              throw new SubmissionError(errors);
            }

            let errors = null;
            try {
              errors = camel(JSON.parse(msg));
            } catch (__) {
              //
            }
            if (errors) {
              const [base, rest] = getBaseError(errors);
              if (base) {
                notify(base, 'error')(dispatch);
                throw new SubmissionError(rest);
              }
              throw new SubmissionError(errors);
            } else {
              notify(msg, 'error')(dispatch);
              throw err;
            }
          }
          (Rollbar as any).error(err);
          notify(_.get(err, 'networkError.result.error') || err.message, 'error')(dispatch);
          throw err;
        }
      }, result)
    }
  </ApolloMutation>
);
export const Mutation = connect()(MutationRender);
