import React from 'react';
import * as input from './graphql';
import { reduxForm, FormSection } from 'redux-form';
import OwnerFields from './OwnerFields';
import { MutationFn } from 'react-apollo';
import Button from 'jbc-front/components/Button';

interface Props {
  owners: input.Owner[];
  answerRequest: MutationFn<input.Data>;
}

export const Form = reduxForm<{}, Props>({
  form: 'input_form',
  onSubmit: async (values, dispatch, { answerRequest }) => {
    await answerRequest({
      variables: {
        input: values
      }
    });
  }
})(({ owners, handleSubmit, submitting }) => (
  <form onSubmit={handleSubmit}>
    {owners.map((owner, index) => (
      <FormSection name={`owners[${index}]`} key={owner.id}>
        <OwnerFields owner={owner} />
      </FormSection>
    ))}
    <div className="u-ta-c u-mt30">
      <Button primary onClick={handleSubmit} disabled={submitting}>
        入力する
      </Button>
    </div>
  </form>
));

export const makeInitialValues = (owners: input.Owner[]) => ({ owners: owners.map(owner => ({ ownerId: owner.id })) });
