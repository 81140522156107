import React from 'react';
import Notifications from 'reapop';
import theme from 'reapop-theme-wybo';
import Footer from './components/Footer';
import styles from './Layout.scss';

const Layout: React.SFC<{}> = ({ children }) => (
  <div>
    <Notifications theme={theme} />
    <div className={styles.main}>
      <div className="l-main-contents">{children}</div>
      <Footer />
    </div>
  </div>
);

export default Layout;
